




















import './scss/BaseSelectOption.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'BaseSelectOption',

    components: {
        BaseCheckbox: () => import('@components/BaseCheckbox'),
    },
})
export default class BaseSelectOption extends Vue {
    $refs!: {
        checkboxRef: HTMLFormElement,
    }

    @Prop({}) option!: any;
    @Prop({}) selected!: any;
    @Prop({}) multiselectable!: any;
    @Prop({}) isOptionDisabled!: boolean;
    @Prop({}) disabled!: boolean;

    unselected: boolean = false;

    emitOptionSelect() {
        this.unselected = this.selected;
        if (!this.isOptionDisabled) this.$emit('option-select', this.option);
    }

    get optionLabel() {
        if (typeof this.option === 'object') return this.option.label || this.option.title;
        return this.option;
    }
}
